import React, { useEffect, useRef, useState } from "react";
import { FaPaperPlane } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector, RootState, templateSelector } from "../../store/store";
import {
  setSelectedTemplate,
  setTemplates,
} from "../../store/slices/templateSlice";
import { createTemplate } from "../../store/features/ownerActions";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { fetchTemplates } from "../../utils";
import { toast } from "react-toastify";
import {
  setActionsAdded,
  setNewCampaignData,
} from "../../store/slices/ownerSlice";
import { IoMdBulb } from "react-icons/io";
import { MdCancel, MdPerson } from "react-icons/md";
import { replace } from "lodash";

type Props = {
  onClose: () => void;
};

const AddMessageModal = (props: Props) => {
  const { templates, selectedTemplate, loading } =
    useSelector(templateSelector);
  const { owner, newCampaignData, actionsAdded } = useSelector(ownerSelector);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const modalSelfRef = useRef<HTMLDivElement>(null);

  const [onFocused, setOnFocused] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        modalSelfRef.current &&
        !modalSelfRef.current.contains(e.target as Node)
      ) {
        props.onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);
  const handleTemplateSave = async () => {
    if (selectedTemplate.name === "" || selectedTemplate.content.body === "") {
      toast.error("Template name and body cannot be empty");
      return;
    }

    if (selectedTemplate.content.body.length > 8000) {
      toast.error("Message body cannot be more than 8000 characters");
      return;
    }

    const res = await dispatch(createTemplate(selectedTemplate));

    if (res.meta.requestStatus === "fulfilled") {
      toast.success("Template saved successfully");
      const templates = await fetchTemplates(owner?._id as string);
      dispatch(setTemplates(templates));
      dispatch(setSelectedTemplate(templates[0]));
      // setAddingMessage(false);
      // setSelectingActions(true);
    } else {
      toast.error("Error saving template");
    }
  };
  return (
    <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-sm bg-black/20">
      <div>
        <div
          ref={modalSelfRef}
          className="bg-white text-xs w-[80%] absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 rounded-lg p-4"
        >
          <span
            onClick={props.onClose}
            className="text-mainColor absolute right-4 cursor-pointer"
          >
            <MdCancel size={25} />
          </span>
          <div className="p-4 flex w-full  justify- items-start">
            <div className="flex flex-col gap-4 items-start justify-start w-[70%] border-r-2 px-4">
              <div className="flex justify-center items-center font-semibold gap-2">
                <span className="bg-mainColor p-2 text-white text-center rounded-lg">
                  <FaPaperPlane size={20} />
                </span>
                <p>Edit message</p>
              </div>
              <div className="flex gap-2 w-full"> 
                <span className="w-full">
                <p style={{ fontWeight: 600, padding: "5px" }}>Name your message template</p>
                <input
                  onChange={(event) => {
                    dispatch(
                      setSelectedTemplate({
                        ...selectedTemplate,
                        name: event.target.value,
                        content: {
                          ...selectedTemplate.content,
                          subject: event.target.value,
                        },
                      })
                    );
                  }}
                  value={selectedTemplate.name || ""}
                  type="text"
                  placeholder="Name your message template"
                  className="p-2 border-2 border-mainColor/20 w-full outline-none rounded-md"
                />
                </span>
                <span className="w-full">
                <p style={{ fontWeight: 600, padding: "5px" }}>Select a template</p>
                <select
                  defaultValue={selectedTemplate.id}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    const selected = templates.find((template: any) => {
                      return template._id === e.target.value;
                    });

                    dispatch(setSelectedTemplate(selected));
                  }}
                  className="p-2 border-2 border-mainColor/20 w-full outline-none rounded-md"
                  name=""
                  id=""
                >
                  {templates.map((template: any) => (
                    <option key={template._id} value={template._id}>
                      {template.name}
                    </option>
                  ))}
                </select>
                </span>
              </div>
              <div className="bg-primary w-full h-[50vh] border-mainColor/50 border-2 rounded-lg relative">
                <p className="absolute right-1 text-xs top-1 ">
                  {selectedTemplate?.content.body.length || 0}/8000
                </p>
                <textarea
                  maxLength={8000}
                  id="message"
                  value={selectedTemplate.content.body || ""}
                  onClick={() => setOnFocused(true)}
                  onChange={(event) => {
                    dispatch(
                      setSelectedTemplate({
                        ...selectedTemplate,
                        content: {
                          ...selectedTemplate.content,
                          body: event.target.value,
                        },
                      })
                    );
                  }}
                  className="outline-none resize-none text-start w-full h-full bg-primary p-2"
                  placeholder="Type your message here..."
                />
              </div>
              <div className="w-full flex justify-between">
                <select
                  onChange={(e) => {
                    console.log(onFocused);
                    if (!onFocused) {
                      dispatch(
                        setSelectedTemplate({
                          ...selectedTemplate,
                          content: {
                            ...selectedTemplate.content,
                            body:
                              selectedTemplate.content.body +
                              " " +
                              e.target.value,
                          },
                        })
                      );
                    } else {
                      const message = document.getElementById("message") as any;
                      if (message) {
                        const start = message.selectionStart;
                        const end = message.selectionEnd;
                        const text = message.value;
                        const before = text.substring(0, start);
                        const after = text.substring(end, text.length);
                        const selectedText = text.substring(start, end);
                        message.value = before + e.target.value + after;
                        message.selectionStart = start + e.target.value.length;
                        message.selectionEnd = start + e.target.value.length;
                        dispatch(
                          setSelectedTemplate({
                            ...selectedTemplate,
                            content: {
                              ...selectedTemplate.content,
                              body:
                                before + e.target.value + after + selectedText,
                            },
                          })
                        );
                      }
                    }
                    e.target.selectedIndex = 0;
                  }}
                  name=""
                  id=""
                  className="p-2 border-2 border-mainColor/20  outline-none rounded-md"
                >
                  <option value="Insert" disabled selected>
                    Insert a variable
                  </option>
                  <option value="{{firstName}}">{`{{firstname}}`}</option>
                  <option value="{{lastName}}">{`{{lastname}}`}</option>

                  <option value="{{company}}">{`{{company}}`}</option>
                  <option value="{{position}}">{`{{position}}`}</option>
                  <option value="{{location}}">{`{{location}}`}</option>
                  {newCampaignData.prospects &&
                  newCampaignData.prospects.some(prospect => prospect?.videoLink) && 
                  <option value="{{videolink}}">{`{{videolink}}`}</option>}
                </select>
                <div className="flex gap-2">
                  <button
                    disabled={loading}
                    onClick={handleTemplateSave}
                    className=" border-2 border-mainColor  p-2 px-4 rounded-md font-semibold text-mainColor"
                  >
                    Save Template
                  </button>
                  <button
                    onClick={() => {
                      if (
                        newCampaignData.actions[
                          newCampaignData.actions?.length - 1
                        ]?.type === "delay"
                      ) {
                        dispatch(
                          setNewCampaignData({
                            ...newCampaignData,
                            actions: [
                              ...newCampaignData.actions,
                              {
                                type: "messageWithDelay",
                                message: selectedTemplate.content,
                                delay:
                                  newCampaignData.actions[
                                    newCampaignData.actions.length - 1
                                  ].delay,
                              },
                            ],
                          })
                        );
                      } else {
                        dispatch(
                          setNewCampaignData({
                            ...newCampaignData,
                            actions: [
                              ...newCampaignData.actions,
                              {
                                type: "message",
                                message: selectedTemplate.content,
                              },
                            ],
                          })
                        );
                      }
                      dispatch(setActionsAdded([...actionsAdded, "Message"]));
                      props.onClose();
                    }}
                    className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
                  >
                    Add More
                  </button>
                </div>
              </div>
            </div>
            <div className="w-[30%] gap-2 flex flex-col px-4">
              <div className="border-2 flex w-full justify-start gap-4 font-semibold items-center text-white bg-mainColor p-4 rounded-md">
                <IoMdBulb size={30} />
                <span>Message Preview</span>
              </div>
              <div className="bg-primary w-full  border-mainColor/50 border-2 rounded-lg p-4 flex flex-col">
                <div className="flex w-full flex-col gap-4 items-start">
                  <div className="flex justify-center items-center gap-2">
                    <span className="p-1 text-mainColor bg-mainColor/20 rounded-lg">
                      <MdPerson size={30} />
                    </span>
                    <h1 className="font-semibold">John Doe</h1>
                  </div>
                  <div className="bg-white w-full h-full p-2 rounded-md text-sm">
                    <textarea
                      disabled
                      value={
                        selectedTemplate.content.body
                          .replace(/{{company}}/g, "Leadseeder")
                          .replace(/{{position}}/g, "CEO")
                          .replace(/{{location}}/g, "San Francisco, CA")
                          .replace(/{{firstName}}/g, "John")
                          .replace(/{{lastName}}/g, "Doe")
                          .replace(/{{videolink}}/g, "https://sendspark.com/share/d/") || ""
                      }
                      placeholder="Subject"
                      className="outline-none text-xs bg-white resize-none w-full h-32"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMessageModal;

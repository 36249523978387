import React, { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector, RootState } from "../../store/store";
import { Prospect, ProspectTableColumns } from "../../types";
import ProspectTable from "../propects/ProspectTable";
import { Checkbox, Popover, Table, TableColumnType } from "antd";
import { RxAvatar } from "react-icons/rx";
import {
  FaAt,
  FaCalendar,
  FaPaperclip,
  FaPeopleGroup,
  FaPlus,
} from "react-icons/fa6";
import { BsLinkedin } from "react-icons/bs";
import { MdClose, MdEmail } from "react-icons/md";
import { IoIosPaperPlane, IoMdEye } from "react-icons/io";
import { toast } from "react-toastify";
import { checkPermission } from "../../utils";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import {
  createCampaign,
  saveCampaign,
} from "../../store/features/ownerActions";
import {
  setActionsAdded,
  setNewCampaignData,
} from "../../store/slices/ownerSlice";
import { useNavigate } from "react-router-dom";
import { setCurrentStep } from "../../store/slices/campaignSlice";

type Props = {
  setShowExpiredAlert: (value: boolean) => void;
};
// Dummy data arrays
const campaignName = "Awesome Campaign";

const actionsSequence = ["Invitation", "Message", "Delay"];

// Modal component for viewing prospects
const ProspectModal = ({
  isOpen,
  onClose,
  setShowExpiredAlert,
}: {
  isOpen: boolean;
  onClose: () => void;
  setShowExpiredAlert: (value: boolean) => void;
}) => {
  const modalSelfRef = useRef<HTMLDivElement>(null);
  const { newCampaignData, actionsAdded, owner } = useSelector(ownerSelector);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        modalSelfRef.current &&
        !modalSelfRef.current.contains(e.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  const columns: TableColumnType<ProspectTableColumns>[] = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Company/Job",
      dataIndex: "job",
    },
    {
      title: "Statuses",
      dataIndex: "statuses",
    },

    {
      title: "Tags",
      dataIndex: "tags",
    },
  ];

  const tableData: ProspectTableColumns[] = newCampaignData.prospects.map(
    (prospect) => {
      return {
        key: prospect._id,
        name: (
          <div className="flex font-primary  items-center gap-2 cursor-pointer">
            {prospect.image_url ? (
              <img
                src={prospect.image_url}
                alt=""
                className="w-8 h-8 rounded-full"
              />
            ) : (
              <RxAvatar
                className="w-10 h-10 text-mainColor cursor-pointer"
                // size={20}
              />
            )}
            <p className="whitespace-nowrap text-xs">
              {prospect.full_name?.length > 20 ? (
                <Popover content={prospect.full_name}>
                  {prospect.full_name.slice(0, 20) + "..."}
                </Popover>
              ) : (
                prospect.full_name
              )}
            </p>
          </div>
        ),
        job: (
          <div className="flex gap-2 font-primary">
            {prospect.current_company_name && prospect.title ? (
              <p className="text-xs">
                {prospect.title} at {prospect.current_company_name}
              </p>
            ) : (
              <p className="text-xs">{prospect.headline}</p>
            )}
          </div>
        ),
        statuses: (
          <div className="flex gap-2">
            <Popover
              content={
                prospect.connected === "ACCEPTED"
                  ? "Connected"
                  : "Not Connected"
              }
            >
              <FaPaperclip
                className={`${
                  prospect.connected === "ACCEPTED"
                    ? "text-mainColor cursor-pointer"
                    : "text-gray-400 cursor-pointer"
                } `}
                size={15}
              />
            </Popover>

            <Popover
              content={
                prospect.sameGroup
                  ? "In the same group"
                  : "Not in the same group"
              }
            >
              <FaPeopleGroup
                className={`${
                  prospect.sameGroup ? "text-mainColor" : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            <Popover
              content={
                prospect.sameEvent
                  ? "In the same event"
                  : "Not in the same event"
              }
            >
              <FaCalendar
                className={`${
                  prospect.sameEvent ? "text-mainColor" : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            <Popover
              content={
                prospect.contactInfo?.emailAddress
                  ? "Has email address"
                  : "No email address"
              }
            >
              <FaAt
                className={`${
                  prospect.contactInfo?.emailAddress
                    ? "text-mainColor"
                    : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            <Popover
              content={
                prospect.isPremium ? "Is a premium user" : "Not a premium user"
              }
            >
              <BsLinkedin
                className={`${
                  prospect.isPremium ? "text-amber-500/70" : "text-gray-400 "
                } cursor-pointer`}
                size={15}
              />
            </Popover>
          </div>
        ),
        actions: (
          <div className="flex gap-2">
            <Popover
              content={
                prospect.connected !== "NOT_STARTED"
                  ? "Invitation Sent"
                  : "No Invitation Sent"
              }
            >
              <MdEmail
                className={`${
                  prospect.connected !== "NOT_STARTED"
                    ? "text-mainColor"
                    : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            {/* <Popover content="No follow sent">
          <MdRssFeed
            className="text-gray-400 cursor-pointer"
            size={20}
          />
        </Popover> */}
            <Popover
              content={
                prospect.profileVisited
                  ? "Profile Visited"
                  : "Profile not visited"
              }
            >
              <IoMdEye
                className={`${
                  prospect.profileVisited ? "text-mainColor" : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            {/* <Popover content="No message sent">
        <MdMailOutline
          className="text-gray-400 cursor-pointer"
          size={20}
        />
      </Popover> */}
            {/* <Popover content="No Message request sent">
          <MdMessage
            className="text-gray-400 cursor-pointer"
            size={20}
          />
        </Popover> */}
            <Popover
              content={
                prospect.messageSent ? "Message Sent" : "No message sent"
              }
            >
              <IoIosPaperPlane
                className={`${
                  prospect.messageSent ? "text-mainColor" : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
          </div>
        ),
        tags: (
          <div className="relative flex gap-1 justify-center items-center">
            {prospect.tags.length > 0 ? (
              prospect.tags.length > 1 ? (
                <Popover
                  content={
                    <div className="flex flex-col gap-2">
                      {prospect.tags.map((tag: any) => (
                        <div key={tag._id} className="flex items-center gap-2">
                          <p
                            style={{ backgroundColor: tag.color }}
                            className={`text-xs py-0.5 px-2 cursor-pointer whitespace-nowrap rounded-xl text-white`}
                          >
                            {tag.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div className="flex gap-2 items-center">
                    <p className="text-xs text-gray-400">
                      +{prospect.tags.length}
                    </p>
                    <FaPaperclip
                      className="text-mainColor cursor-pointer"
                      size={15}
                    />
                  </div>
                </Popover>
              ) : (
                <div className="flex items-center gap-2">
                  <p
                    style={{ backgroundColor: prospect.tags[0].color }}
                    className={`text-xs py-0.5 px-2 cursor-pointer rounded-xl text-white whitespace-nowrap`}
                  >
                    {prospect.tags[0].name}
                  </p>
                </div>
              )
            ) : (
              <p className="text-xs p-1 whitespace-nowrap text-center bg-gray-500/50 text-white rounded-md">
                No tags
              </p>
            )}
          </div>
        ),
      };
    }
  );

  return (
    <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-sm bg-black/20">
      <div>
        <div
          ref={modalSelfRef}
          className="bg-white text-xs w-[80%] absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 rounded-lg p-4"
        >
          <Table
            style={{
              height: `calc(100vh - 200px)`,
              overflowY: "scroll",
            }}
            id="prospects"
            className="text-xs font-primary slimScrollbar"
            columns={columns}
            dataSource={tableData}
            pagination={
              tableData.length > 10
                ? {
                    position: ["bottomRight"],
                    pageSize: 10,
                    showSizeChanger: false,
                  }
                : false
            }
          />

          <button
            onClick={onClose}
            className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const ReviewCampaign = (props: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { newCampaignData, actionsAdded, owner } = useSelector(ownerSelector);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const navigate = useNavigate();

  const handleCampaignStart = async () => {
    // console.log(newCampaignData);

    if (actionsAdded.length === 0) {
      toast.error("Please add atleast one action");
      return;
    }

    if (newCampaignData.prospects.length === 0) {
      toast.error("Selected list have no prospects");
      return;
    }
    if (newCampaignData.actions.length === 0) {
      toast.error("Please add atleast one action");
      return;
    }
    const allowed = owner?._id ? await checkPermission(owner?._id) : true;
    if (!allowed) {
      props.setShowExpiredAlert(true);
      return;
    }
    const loader = toast.loading("Creating your Campaign...");
    const reqData = {
      ...newCampaignData,
      ownerId: owner?._id,
      prospects: newCampaignData.prospects.map(prospect => prospect._id),
      actions: newCampaignData.actions.filter(
        (action: any) => action.type !== "delay"
      ),
    };
    // console.log(reqData);
    try {
      const res = await dispatch(createCampaign(reqData));
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss(loader);
        toast.success("Campaign Created");
        dispatch(
          setNewCampaignData({
            ownerId: owner?._id,
            listId: "",
            campaignName: "Invitation",
            prospects: [],
            actions: [],
          })
        );
        navigate("/campaigns/list/running");
        dispatch(
          setCurrentStep({
            idx: 0,
            key: "campaignName",
          })
        );

        dispatch(setActionsAdded([]));
        return;
      }
      if (res.meta.requestStatus === "rejected") {
        toast.dismiss(loader);

        if (res.payload === "Request failed with status code 400") {
          toast.error("You can only run one campaign at a time");
        } else {
          toast.error("Failed to launch campaign");
        }
        // toast.error("Something went wrong");
      }
    } catch (error) {
      toast.dismiss(loader);
      toast.error("Something went wrong");
    }
  };

  const handleCampaignSave = async () => {
    // console.log(newCampaignData);

    if (actionsAdded.length === 0) {
      toast.error("Please add atleast one action");
      return;
    }

    if (newCampaignData.prospects.length === 0) {
      toast.error("Selected list have no prospects");
      return;
    }
    if (newCampaignData.actions.length === 0) {
      toast.error("Please add atleast one action");
      return;
    }
    const loader = toast.loading("Saving your Campaign...");
    const reqData = {
      ...newCampaignData,
      campaignStatus: "DRAFT",
      ownerId: owner?._id,
      actions: newCampaignData.actions.filter(
        (action: any) => action.type !== "delay"
      ),
    };
    // console.log(reqData);
    try {
      const res = await dispatch(saveCampaign(reqData));
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss(loader);
        toast.success("Campaign saved as draft");
        dispatch(
          setNewCampaignData({
            ownerId: owner?._id,
            campaignName: "Invitation",
            prospects: [],
            actions: [],
          })
        );
        navigate("/campaigns/list/draft");
        dispatch(
          setCurrentStep({
            idx: 0,
            key: "campaignName",
          })
        );

        dispatch(setActionsAdded([]));
        return;
      }
      if (res.meta.requestStatus === "rejected") {
        toast.dismiss(loader);

        if (res.payload === "Request failed with status code 400") {
          toast.error("You can only run one campaign at a time");
        } else {
          toast.error("Failed to save campaign");
        }
        // toast.error("Something went wrong");
      }
    } catch (error) {
      toast.dismiss(loader);
      toast.error("Something went wrong");
    }
  };
  return (
    <div className="p-6 flex flex-col w-full items-center bg-white mx-6 rounded-md ">
      <h1 className="text-xl font-bold mb-4">Review Your Campaign</h1>

      {/* Campaign Name */}
      <div className="mb-4">
        <h2 className="text-sm font-semibold">
          Campaign Name :{" "}
          <span className="text-green-600">{newCampaignData.campaignName}</span>
        </h2>
      </div>

      {/* Number of Prospects */}
      <div className="mb-4 flex items-center gap-2">
        <h2 className=" font-semibold">
          Number of Prospects : {newCampaignData.prospects.length}
        </h2>
        <button
          onClick={() => setModalOpen(true)}
          className=" tet-xs flex items-center bg-blue-500 text-white px-3 py-1 rounded-md"
        >
          <FaEye className="mr-2" />
          <span className="text-xs">View Prospects</span>
        </button>
      </div>

      {/* Actions Sequence */}
      <div className="mb-4 flex justify-start items-start">
        <h2 className=" font-semibold">Actions :</h2>
        <ul className="list-disc ml-6">
          {actionsAdded.map((action, index) => (
            <li key={index} className="text-md">
              {action}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6 flex gap-4">
        <button
          onClick={handleCampaignStart}
          className="bg-mainColor text-white px-4 py-2 rounded-md font-semibold"
        >
          Launch Campaign
        </button>
        <button
          onClick={handleCampaignSave}
          className="bg-gray-500 text-white px-4 py-2 rounded-md font-semibold"
        >
          Save as Draft
        </button>
      </div>

      <ProspectModal
        setShowExpiredAlert={props.setShowExpiredAlert}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};

export default ReviewCampaign;

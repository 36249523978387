import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector, prospectsSelector, RootState } from "../../store/store";
import { MdCancel, MdCircle, MdClose, MdDelete } from "react-icons/md";
import {
  setListDescription,
  setListName,
  setProspects,
  setSelectedProspects,
} from "../../store/slices/prospectsSlice";
import { AiOutlineEnter, AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { setListId, setLists } from "../../store/slices/ownerSlice";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { addTagToProspect } from "../../store/features/ownerActions";
import { fetchLists, fetchProspects } from "../../utils";
type Props = {
  setSelectedArray: Dispatch<SetStateAction<string[]>>;
};

const ProspectsSettings = (props: Props) => {
  const { lists, listId, tags, owner, currentWorkspace, allProspectsList } =
    useSelector(ownerSelector);
  const { selectedProspects, listName } = useSelector(prospectsSelector);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const [showMove, setShowMove] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [moving, setMoving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleProspectMove = async (currentId: string) => {
    setMoving(true);
    // console.log(currentId);
    try {
      const res = await axios.post("/prospect/addProspectToList", {
        listId: currentId,
        prospectIds: selectedProspects,
        removeListId: listId,
      });
      if (res.status === 200) {
        // console.log(res.data);
        setMoving(false);
        const lists = await fetchLists(
          owner?._id as string,
          currentWorkspace._id as string
        );
        dispatch(setLists(lists));
        dispatch(setListName(res.data.listName));
        dispatch(setListDescription(res.data.description));
        dispatch(setListId(res.data._id));
        dispatch(setSelectedProspects([]));
        props.setSelectedArray([]);
        toast.success("Prospects moved successfully");
      }
    } catch (error) {
      toast.error("An error occurred while moving prospects");
      console.error(error);
    }
  };

  const handleProspectDelete = async () => {
    setDeleting(true);
    const loading = toast.loading("Deleting prospects...");
    try {
      const res = await axios.post("/prospect/deleteProspectsFromList", {
        listId: listId,
        prospectIds: selectedProspects,
        allProspectsListId: allProspectsList._id,
      });
      if (res.status === 200) {
        console.log(res.data);
        setDeleting(false);
        dispatch(setListName(res.data.listName));
        dispatch(setListId(res.data._id));
        dispatch(setProspects(res.data.prospects));
        dispatch(setSelectedProspects([]));
        props.setSelectedArray([]);
        toast.dismiss(loading);
        toast.success("Prospects deleted successfully");
      }
    } catch (error) {
      toast.error("An error occurred while deleting prospects");
      console.error(error);
    }
  };

  const handleBulkTagging = async (tagId: string) => {
    try {
      const res = await dispatch(
        addTagToProspect({ prospectIds: selectedProspects, tagId })
      );
      if (res.payload.success) {
        const list = await fetchProspects(owner?._id as string, listId, 1, 10);
        dispatch(setProspects(list.prospects));
        dispatch(setSelectedProspects([]));
        props.setSelectedArray([]);
        toast.success("Tag added to prospects successfully");
      } else if (!res.payload.success) {
        toast.error(res.payload.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
    }
  };
  return (
    <div className="absolute mx-auto gap-6  left-[40%] flex items-center justify-around z-50 p-4 bg-primary shadow-lg text-xs top-0 px-4">
      <span
        className=" cursor-pointer"
        onClick={() => {
          dispatch(setSelectedProspects([]));
          props.setSelectedArray([]);
        }}
      >
        <MdClose size={20} />
      </span>
      <p>
        <span className="p-1 px-2 bg-mainColor/20 rounded-lg">
          {selectedProspects.length}
        </span>{" "}
        prospect
        {selectedProspects.length > 1 ? "s" : ""} selected
      </p>
      <div className="flex gap-4 text-sm">
        <button
          disabled={deleting}
          onClick={() => {
            setShowMove(!showMove);
          }}
          className="bg-white relative flex gap-2 justify-center items-center rounded-lg border-2 border-mainColor text-xs font-semibold p-1"
        >
          {moving ? (
            <div className="flex gap-2">
              <span className="animate-spin">
                <AiOutlineLoading3Quarters size={15} />
              </span>
              <p>Moving</p>
            </div>
          ) : (
            <div className="flex">
              <span>
                <AiOutlineEnter size={15} />
              </span>
              <p>Move to</p>
            </div>
          )}
        </button>
        {showMove && (
          <div className="absolute top-full noScroll text-xs h-[200px] overflow-y-auto bg-white p-4 rounded-md shadow-lg z-50">
            {lists.map((list: any) => {
              if (
                list.listName !== listName &&
                !list.isDeleted &&
                list.inActiveCampaign === false && 
                list.listName !== "All Prospects"
              ) {
                return (
                  <button
                    key={list._id}
                    onClick={() => {
                      setShowMove(false);
                      handleProspectMove(list._id);
                    }}
                    className="flex hover:bg-primary w-full rounded-lg gap-2 justify-center items-center p-2"
                  >
                    <p>{list.listName}</p>
                  </button>
                );
              }
            })}
          </div>
        )}
        <button
          disabled={deleting}
          onClick={() => {
            handleProspectDelete();
          }}
          className="bg-red-400/20 flex gap-2 justify-center items-center rounded-lg border-2 border-red-600 font-semibold text-xs p-1 text-red-600"
        >
          <span className="text-red-600">
            <MdDelete size={15} />
          </span>
          <p>Delete</p>
        </button>
        <button
          // disabled={deleting}
          onClick={() => {
            setShowTags(!showTags);
          }}
          className="bg-white relative flex gap-2 justify-center items-center rounded-lg border-2 border-mainColor text-xs font-semibold p-1"
        >
          {moving ? (
            <div className="flex gap-2">
              <span className="animate-spin">
                <AiOutlineLoading3Quarters size={15} />
              </span>
              <p>Moving</p>
            </div>
          ) : (
            <div className="flex">
              {/* <span>
                <AiOutlineEnter size={15} />
              </span> */}
              <p>Add Tag</p>
            </div>
          )}
        </button>
        {showTags && (
          <div className="absolute top-full noScroll text-xs h-[200px] overflow-y-auto bg-white p-4 rounded-md shadow-lg z-50 right-0">
            {tags.map((tag) => {
              return (
                <button
                  key={tag._id}
                  onClick={() => {
                    setShowTags(false);
                    handleBulkTagging(tag._id);
                  }}
                  className="flex hover:bg-primary w-full rounded-lg gap-2 justify-center items-center p-2"
                >
                  <p>{tag.name}</p>
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProspectsSettings;

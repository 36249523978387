import { useEffect, useState } from "react";
import {
  FaAngleDown,
  FaAngleUp,
  FaChartColumn,
  FaEnvelopeCircleCheck,
} from "react-icons/fa6";
import { HiMiniLink } from "react-icons/hi2";
import { MdMessage } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { campaignSelector, dashboardSelector } from "../../store/store";
import Progresscard from "./Progresscard";
import StatsCardReusable from "./StatsCardReusable";
import { setDashCollapsed } from "../../store/slices/dashboardSlice";

type Props = {};

// const actionfilter = [
//   {
//     id: 1,
//     name: "Invitation",
//     icon: <FaLink size={25} />,
//   },
//   {
//     id: 2,
//     name: "Message requests",
//     icon: <MdMessage size={25} />,
//   },
//   {
//     id: 3,
//     name: "Messages",
//     icon: <IoMdPaperPlane size={25} />,
//   },
//   {
//     id: 4,
//     name: "Visit",
//     icon: <FaEye size={25} />,
//   },
// ];

const LinkedinGraphs = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState("Invitation");
  const { linkedinData, dashCollapsed } = useSelector(dashboardSelector);
  const { singleCampaign, selectedCampaign } = useSelector(campaignSelector);
  const [totalInvitationSent, setTotalInvitationSent] = useState(0);
  const [invitationPercentage, setInvitationPercentage] = useState(0);
  const [answeredMessages, setAnsweredMessages] = useState(0);
  const [responseRate, setResponseRate] = useState(0);
  const [acceptedInvitations, setAcceptedInvitations] = useState(0);
  const [acceptanceRate, setAcceptanceRate] = useState(0);
  const [messageProgress, setMessageProgress] = useState(0);
  const [messagePercentage, setMessagePercentage] = useState(0);
  const dispatch = useDispatch();
  // const;

  useEffect(() => {
    if (singleCampaign) {
      // console.log(singleCampaign);
      const totalInvitationSent =
        singleCampaign.prospectsWiseCampaignActions.filter(
          (prospect) =>
            prospect?.campaignActions?.filter(
              (action: any) =>
                action.type === "invitation" && action.done === "DONE"
            ).length
        ).length;
      setTotalInvitationSent(totalInvitationSent);
      const invitationPercentage = Math.round(
        (totalInvitationSent / singleCampaign.prospects.length) * 100
      );
      setInvitationPercentage(invitationPercentage);

      const answeredMessages =
        singleCampaign.prospectsWiseCampaignActions.filter(
          (prospect) =>
            prospect?.campaignActions?.filter(
              (action: any) => action?.done === "HASREPLIED"
            ).length
        ).length;

      setAnsweredMessages(answeredMessages);

      const responseRate = Math.round(
        (answeredMessages / singleCampaign.prospects.length) * 100
      );
      setResponseRate(responseRate);

      const acceptedInvitations = singleCampaign.prospects.filter(
        (prospect) => prospect.connected === "ACCEPTED"
      ).length;
      setAcceptedInvitations(acceptedInvitations);

      const acceptanceRate = Math.round(
        (acceptedInvitations / singleCampaign.prospects.length) * 100
      );
      setAcceptanceRate(acceptanceRate);

      let messageProgress = 0;

      singleCampaign.prospectsWiseCampaignActions.map(
        (prospect) =>
          prospect?.campaignActions?.map(
            (action: any) =>{
              if((action.type === "message" || action.type === "messageWithDelay") && action.done === "DONE"){
                messageProgress++;
              }
            }
          )
      );
      setMessageProgress(messageProgress);

      const messagePercentage = Math.round(
        (messageProgress / singleCampaign.prospects.length) * 100
      );
      setMessagePercentage(messagePercentage);
    }
  }, [singleCampaign]);
  return (
    <section className="flex gap-4 flex-col">
      <h1 className="flex gap-2 items-center">
        <FaChartColumn size={20} className="text-mainColor " />
        <p className="text-sm font-semibold">Campaign Details</p>
        <span
          className=" cursor-pointer"
          onClick={() => dispatch(setDashCollapsed(!dashCollapsed))}
        >
          {dashCollapsed ? (
            <FaAngleDown size={18} className="text-mainColor" />
          ) : (
            <FaAngleUp size={18} className="text-mainColor" />
          )}
        </span>
      </h1>
      {!dashCollapsed && (
        <>
          <div className="w-full flex-flex-col gap-4">
            <div className="flex w-full gap-4">
              <StatsCardReusable
                info="Invitations sent to prospects"
                icon={<FaEnvelopeCircleCheck size={25} />}
                statLabel="Invitations Sent"
                statNumber={totalInvitationSent}
                progressValue={invitationPercentage}
                percentageLabel="Invitation Progress"
                className="w-1/2"
              />

              <StatsCardReusable
                info="Responses to invitation notes and messages"
                icon={<MdMessage size={25} />}
                statLabel="Messages Sent"
                statNumber={messageProgress || 0}
                progressValue={messagePercentage || 0}
                percentageLabel="Message Progress"
                className="w-1/2"
              />
            </div>
            {/* <div className="bg-white rounded-lg mt-4 flex flex-col gap-4 p-4 shadow-lg">
          <h1 className="text-xl font-semibold">Actions sent</h1>
          <div className="w-full flex gap-4">
            {actionfilter.map((action) => (
              <div
                onClick={() => setSelectedTab(action.name)}
                key={action.id}
                className={`flex flex-col ${
                  selectedTab === action.name && "border-2 border-mainColor"
                } rounded-lg p-4 w-[20%] h-[150px] bg-primary justify-center items-center gap-2 cursor-pointer hover:bg-mainColor/10`}
              >
                <span className="flex flex-col justify-center items-center gap-2">
                  <p className="p-2 bg-mainColor/20 rounded-full">
                    {action.icon}
                  </p>
                  <p className="text-xl font-semibold">30</p>
                </span>
                <p className="text-sm">{action.name}</p>
              </div>
            ))}
          </div>
          <div className="w-full flex flex-col gap-2">
            <p>Invitation</p>
            <ChartReusable />
          </div>
        </div> */}
          </div>
          <div className="w-full flex-flex-col gap-4">
            <div className="flex w-full gap-4">
              <Progresscard />
              <StatsCardReusable
                info="Responses to invitation notes and messages"
                icon={<MdMessage size={25} />}
                statLabel="Answered Messages"
                statNumber={answeredMessages || 0}
                progressValue={responseRate || 0}
                percentageLabel="Response Rate"
                className="w-1/2"
              />
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default LinkedinGraphs;

import React from "react";
import { RxAvatar } from "react-icons/rx";
import { Link } from "react-router-dom";
import { FiActivity } from "react-icons/fi";
import { AiFillDollarCircle } from "react-icons/ai";
type Props = {};

const settings = [
  {
    title: "Profile",
    key: "profile",
    path: "/settings/profile",
    icon: <RxAvatar size={20} />,
  },
  // {
  //   title: "Billing",
  //   key: "billing",
  //   path: "/settings/billing",
  //   icon: <AiFillDollarCircle size={20} />,
  // },
  {
    title: "Account Activity",
    key: "account",
    path: "/settings/account-activity",
    icon: <FiActivity size={20} />,
  },
  //   {
  //     title: "Notification",
  //     key: "notification",
  //   },
  //   {
  //     title: "Security",
  //     key: "security",
  //   },
];

const SettingSider = (props: Props) => {
  return (
    <div className="flex w-full">
      <ul className="w-full px-4">
        {settings.map((setting) => (
          <li
            key={setting.key}
            className="p-2 border-mainColor border-2 bg-white w-full rounded-lg shadow-md my-2"
          >
            <Link
              to={setting.path}
              className="text-xs font-semibold flex justify-start items-center gap-2"
            >
              {setting.icon}
              <p>{setting.title}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingSider;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import _ from "lodash";
import { utils, writeFile } from "xlsx";
import {
  FaUserGroup,
  FaPlus,
  FaFile,
  FaFileExcel,
  FaFileCsv,
  FaLeaf,
} from "react-icons/fa6";
import ProspectTable from "./ProspectTable";
import { ownerSelector, prospectsSelector } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchProspects } from "../../utils";
import {
  setInCampaign,
  setListDescription,
  setListName,
  setProspects,
  setProspectsCount,
} from "../../store/slices/prospectsSlice";
import { toast } from "react-toastify";
import ImportProgressLoader from "../ImportProgressLoader";
import { Link, useNavigate } from "react-router-dom";
import { LINKEDIN_URLS, SOCKET_URL } from "../../constants";
import { MdClose, MdFileOpen, MdInfoOutline } from "react-icons/md";
import axios from "../../utils/axios";
import Papa from "papaparse";
import { setListId } from "../../store/slices/ownerSlice";
import { Socket, io } from "socket.io-client";
import { Popover } from "antd";
import { checkPermission } from "../../utils/index";

type Props = { utils: any; setShowExpiredAlert: any };

const ProspectMain = (props: Props) => {
  const { owner, listId, lists } = useSelector(ownerSelector);
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    limit: 10,
  });
  const [allProspectsList, setAllProspectsList] = useState(
    lists.find((list) => list.listName === "All Prospects")
  );
  const [listChanged, setListChanged] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { prospects, prospectsCount, listName, description } =
    useSelector(prospectsSelector);
  const [showFileModal, setShowFileModal] = useState(false);
  const [showNoProspectDropdown, setShowNoProspectDropdown] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalSelfRef = useRef<HTMLDivElement>(null);
  const noProspectsModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      try {
        if (owner?._id && listId) {
          const res = await fetchProspects(
            owner._id as string,
            listId,
            fetchOptions.page,
            fetchOptions.limit
          );
          // console.log(res);
          dispatch(setProspects(res.prospects));
          dispatch(setProspectsCount(res.totalProspects));
          dispatch(setListName(res.listName));
          dispatch(setListDescription(res.description));
          dispatch(setInCampaign(res.inActiveCampaign));
          setListChanged(true);
        }
      } catch (error) {
        console.log(error);
        toast.error("An error occurred");
      }
    })();
    const handleClickOutside = (e: MouseEvent) => {
      if (
        modalSelfRef.current &&
        !modalSelfRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
        setShowFileModal(false);
        setShowExportOptions(false);
      }
    };

    const handleClickOutsideNoProspect = (e: MouseEvent) => {
      if (
        noProspectsModalRef.current &&
        !noProspectsModalRef.current.contains(e.target as Node)
      ) {
        setShowNoProspectDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideNoProspect);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsideNoProspect);
    };
  }, [owner?._id, listId, uploading]);

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    // console.log(file);

    if (file) {
      setIsFileSelected(true);
      setFile(file);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIsFileSelected(true);
      setFile(e.target.files[0]);

      // console.log(e.target.files[0]);
    }
  };

  const handleImport = async () => {
    if (!file) {
      toast.error("Please select a file to import");
      return;
    }

    if (file.type !== "text/csv") {
      toast.error("Please select a CSV file");
      return;
    }

    if (!listId) {
      toast.error("Please select a list to import to");
      return;
    }

    try {
      const allowed = owner?._id ? await checkPermission(owner?._id) : true;
      if (!allowed) {
        props.setShowExpiredAlert(true);
        return;
      }
      setUploading(true);
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = async (e) => {
        const text = e.target?.result as string;
        // console.log(text);
        const urls: string[] = [];
        const videoLinks: string[] = [];

        text.split(/(?:\r\n|\r|\n)/g).forEach((row)=>{
          row.split(",").forEach((url)=>{
            if(
              url !== undefined
            ){
              if(url.includes("linkedin.com/in/")){
                urls.push(url);
              }else if(url.includes("/share/d/")){
                videoLinks.push(url);
              }
            }
          })
          if(urls.length > videoLinks.length){
            videoLinks.push("")
          }
        })

        // console.log(urls);
        const prospects = urls.map((url) => {
          if (url !== "") {
            return url?.split("/in/")[1].split("/")[0];
          }
        });
        if (prospects?.length === 0) {
          setUploading(false);
          setIsFileSelected(false);
          setFile(null);
          toast.error("This file does not contain any valid Linkedin URLs");
          return;
        }

        setShowFileModal(false);

        let sock = io(SOCKET_URL, {
          transports: ["websocket", "polling"],
        });

        const el = document.getElementById("stopSearching");
        el?.addEventListener("click", () => {
          sock.emit("stopImport", {});
          el.setAttribute("disabled", "");
          props.utils.setVisibility("hidden");
          sock.disconnect();
          setShowFileModal(false);
          setIsFileSelected(false);
          setFile(null);
          setUploading(false);
        });

        sock.on("importinfo", (info: any) => {
          console.log("info", info);
          props.utils.setimportSuccess(info.stats.success);
          props.utils.setimportDuplicates(info.stats.duplicate);
          props.utils.setimportScanned(info.stats.scan);
          props.utils.setimportFalsePositives(info.stats.false);
          props.utils.setimportPercent(
            Number(((info.stats.scan * 100) / info.numberToimport).toFixed(2))
          );
          if (
            info.numberToimport ==
            info.stats.success + info.stats.duplicate + info.stats.false
          ) {
            sock.disconnect();
            props.utils.setVisibility("hidden");
            toast.success("Prospects added successfully");
            setShowFileModal(false);
            setIsFileSelected(false);
            setFile(null);
            setUploading(false);
          }
        });

        props.utils.setimportSuccess(0);
        props.utils.setimportDuplicates(0);
        props.utils.setimportScanned(0);
        props.utils.setimportFalsePositives(0);
        props.utils.setimportPercent(Number(0));

        props.utils.setVisibility("");
        el?.removeAttribute("disabled");

        let res: any;

        sock.on("connect", async () => {
          sock.emit("importCSV", {
            listId,
            prospects,
            ownerId: owner?._id,
            socketId: sock.id,
            videoLinks: videoLinks,
          });
        });
      };
    } catch (error) {
      setUploading(false);
      console.log(error);
      toast.error("An error occurred");
    }
  };

  const handleSearch = useCallback(
    _.debounce(async (query: string) => {
      try {
        if (!owner?._id || !listId) return;
        const { data } = await axios.post("/prospect/searchProspectsInList", {
          ownerId: owner._id,
          listId,
          searchQuery: query,
        });
        // console.log(data);
        dispatch(setProspects(data.prospects));

        return true;
      } catch (error) {
        console.log(error);
        toast.error("An error occurred");
      }
    }, 1000),
    [listId]
  );

  const handleExportToCSV = async () => {
    const loading = toast.loading("Exporting prospects...");
    try {
      const exportProspects = await fetchProspects(
        owner?._id as string,
        listId,
        1,
        prospectsCount
      );
      const csvData = exportProspects.prospects.map((prospect: any) => {
        return {
          // name: prospect.full_name,
          first_name: prospect.firstName,
          last_name: prospect.lastName,
          profileURL: "https://www.linkedin.com/in/" + prospect.linkedinId,
          email: prospect.contactInfo?.emailAddress,
          phone:
            prospect.contactInfo?.phoneNumbers?.length > 0
              ? prospect.contactInfo?.phoneNumbers[0]?.number
              : "N/A",
          position: prospect.title,
          company: prospect.current_company_name,
          company_linkedin: prospect.company_linkedin_url,
          company_url: prospect.companyDetails?.url,
          industry: prospect.industry,
          premium: prospect.isPremium ? "Yes" : "No",
          skills: prospect.skills.map((skill: any) => skill.name).join(", "),
          location: prospect.locality,
          prospectList: listName,
          linkedinId: prospect.entityUrn,
        };
      });
      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${listName}.csv`;
      a.click();

      toast.dismiss(loading);
      toast.success("Prospects exported successfully");
      setShowExportOptions(false);
    } catch (error) {
      console.log(error);
      toast.dismiss(loading);
      toast.error("An error occurred");
    }
  };

  const handleExportToExcel = async () => {
    const loading = toast.loading("Exporting prospects...");

    try {
      const exportProspects = await fetchProspects(
        owner?._id as string,
        listId,
        1,
        prospectsCount
      );
      const excelData = exportProspects.prospects.map((prospect: any) => {
        return {
          // name: prospect.full_name,
          first_name: prospect.firstName,
          last_name: prospect.lastName,
          profileURL: "https://www.linkedin.com/in/" + prospect.linkedinId,
          email: prospect.contactInfo?.emailAddress,
          phone:
            prospect.contactInfo?.phoneNumbers?.length > 0
              ? prospect.contactInfo?.phoneNumbers[0]?.number
              : "N/A",
          position: prospect.title,
          company: prospect.current_company_name,
          company_linkedin: prospect.company_linkedin_url,
          company_url: prospect.companyDetails?.url,
          industry: prospect.industry,
          premium: prospect.isPremium ? "Yes" : "No",
          skills: prospect.skills.map((skill: any) => skill.name).join(", "),
          location: prospect.locality,
          prospectList: listName,
          linkedinId: prospect.entityUrn,
        };
      });

      const ws = utils.json_to_sheet(excelData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Prospects");

      writeFile(wb, `${listName}.xlsx`);
      toast.dismiss(loading);
    } catch (error) {
      console.log(error);
      toast.dismiss(loading);

      toast.error("An error occurred");
    }
  };
  return (
    <section className="flex flex-col gap-4 w-full h-full">
      <div className="flex flex-col xl:gap-4 gap-2  ">
        <div className="flex items-center gap-2">
          <h1 className=" text-2xl font-semibold">Prospects </h1>
          <Popover
            placement="right"
            content={
              <p className="text-xs">View and manage all of your prospects</p>
            }
          >
            <MdInfoOutline size={16} />
          </Popover>
        </div>
        <span className="flex flex-col gap-2">
          <div className="flex gap-2">
            <p className=" font-semibold">{listName}</p>
            <span className="flex bg-mainColor/80 rounded-lg text-white gap-1 text-sm p-1 justify-center items-center">
              <FaUserGroup size={15} />
              <p className="text-xs">{prospectsCount}</p>
            </span>
          </div>
          <p className="text-sm">{description}</p>
        </span>
      </div>
      <div className="flex justify-between w-full gap-2 ">
        <div className="flex items-center  gap-2 border-2 border-mainColor bg-white rounded-md p-1 w-[30%]">
          <CiSearch size={20} />
          <input
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              handleSearch(e.target.value);
            }}
            className="text-xs outline-none border-none w-full"
            type="text"
            placeholder="Search"
          />
        </div>
        <div className="relative flex gap-4">
          <button
            onClick={() => setShowExportOptions(!showExportOptions)}
            className="bg-mainColor text-xs flex items-center shadow-md gap-2 font-semibold text-white  p-2 px-4 rounded-md"
          >
            <FaFileExcel className="text-white" size={15} />
            <p>Export</p>
          </button>
          {showExportOptions && (
            <div
              ref={modalSelfRef}
              className="absolute flex flex-col gap-2 top-10 left-0 bg-white p-4 rounded-md shadow-lg z-50 text-xs whitespace-nowrap"
            >
              <button
                onClick={handleExportToCSV}
                className="flex gap-2 hover:bg-mainColor hover:text-white transition-all ease-in-out duration-300 p-2 font-semibold rounded-md text-black"
              >
                <FaFileCsv className="" size={15} />
                <p>Export to CSV</p>
              </button>
              <button
                onClick={handleExportToExcel}
                className="flex gap-2 hover:bg-mainColor hover:text-white transition-all ease-in-out duration-300 p-2 font-semibold rounded-md text-black"
              >
                <FaFileExcel className="" size={15} />
                <p>Export to Excel</p>
              </button>
            </div>
          )}
          {listName !== "All Prospects" && (
            <button
              onClick={() => setShowDropdown(!showDropdown)}
              className="bg-mainColor flex items-center shadow-md gap-2 font-semibold text-white text-xs p-2 px-4 rounded-md"
            >
              <FaPlus className="text-white" size={15} />
              <p>Import</p>
            </button>
          )}
          {showDropdown && (
            <div
              ref={modalSelfRef}
              className="absolute flex flex-col gap-2 top-12 right-0 bg-white p-4 rounded-md shadow-lg z-50 text-xs whitespace-nowrap"
            >
              <Link
                target="_blank"
                to={LINKEDIN_URLS.searchUrl}
                className="flex gap-2  p-2 font-semibold rounded-md text-black"
              >
                <FaPlus className="" size={15} />
                <p>Import via Linkedin Search</p>
              </Link>
              <Link
                target="_blank"
                to={LINKEDIN_URLS.connectionUrl}
                className="flex gap-2  p-2 font-semibold rounded-md text-black"
              >
                <FaPlus className="" size={15} />
                <p>Import your connections</p>
              </Link>
              <Link
                target="_blank"
                to={LINKEDIN_URLS.groupUrl}
                className="flex gap-2  p-2 font-semibold rounded-md text-black"
              >
                <FaPlus className="" size={15} />
                <p>Import from a Group</p>
              </Link>
              <Link
                target="_blank"
                to={LINKEDIN_URLS.eventUrl}
                className="flex gap-2  p-2 font-semibold rounded-md text-black"
              >
                <FaPlus className="" size={15} />
                <p>Import from an Event</p>
              </Link>
              <button
                onClick={() => {
                  setShowFileModal(true);
                  setShowDropdown(false);
                }}
                className="flex gap-2  p-2 font-semibold rounded-md text-black"
              >
                <FaPlus className="" size={15} />
                <p>Import from CSV</p>
              </button>
            </div>
          )}
        </div>
      </div>

      {prospects.length > 0 ? (
        <div className="h-full mb-8">
          <ProspectTable listChanged={listChanged} />
        </div>
      ) : (
        <div className="flex  mt-24 items-center gap-4 flex-col r h-full">
          <FaLeaf size={50} className="text-mainColor " />
          <p className="text-gray-700 text-xl font-semibold">
            Looks like you don't have any prospects yet
          </p>

          <div className="relative">
            {listName !== "All Prospects" &&<button
              onClick={() => setShowNoProspectDropdown(!showNoProspectDropdown)}
              className="bg-mainColor flex items-center shadow-md gap-2 font-semibold text-white text-xs p-2 px-4 rounded-md"
            >
              <FaPlus className="text-white" size={15} />
              <p>Import</p>
            </button>}
            {showNoProspectDropdown && (
              <div
                ref={noProspectsModalRef}
                className="absolute flex flex-col gap-2 top-full bg-white p-4 rounded-md -left-14 mt-2 shadow-lg z-50 text-xs whitespace-nowrap "
              >
                <Link
                  target="_blank"
                  to={LINKEDIN_URLS.searchUrl}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import via Linkedin Search</p>
                </Link>
                <Link
                  target="_blank"
                  to={LINKEDIN_URLS.connectionUrl}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import your connections</p>
                </Link>
                <Link
                  target="_blank"
                  to={LINKEDIN_URLS.groupUrl}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import from a Group</p>
                </Link>
                <Link
                  target="_blank"
                  to={LINKEDIN_URLS.eventUrl}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import from an Event</p>
                </Link>
                <button
                  onClick={() => {
                    setShowFileModal(true);
                    setShowDropdown(false);
                  }}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import from CSV</p>
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {showFileModal && (
        <div>
          <div className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-50 z-50"></div>
          <div className="fixed text-xs inset-0 flex justify-center items-center z-[999]">
            <div
              ref={modalSelfRef}
              className="bg-white w-[50%] min-h-[40%] max-h-[60%] flex flex-col justify-evenly items-center p-4 rounded-lg relative"
            >
              <span
                onClick={() => {
                  setShowFileModal(false);
                  setIsFileSelected(false);
                  setFile(null);
                }}
              >
                <MdClose className="absolute top-2 right-2 text-xl cursor-pointer" />
              </span>
              <div className="w-full flex flex-col items-center justify-center gap-2">
                <h1 className="text-2xl  font-semibold">
                  Upload your CSV file here
                </h1>
                <p className="text-sm">
                  Your file must contain ONLY Linkedin profiles URLs.
                </p>
              </div>
              <div className="flex flex-col items-center justify-center gap-8">
                <label
                  className="flex w-full  border-dashed border-2 border-mainColor rounded-lg h-32 gap-2 flex-col justify-center items-center"
                  htmlFor="file"
                  onDragOver={(e) => handleDragOver(e)}
                  onDrop={(e) => handleDrop(e)}
                >
                  {!isFileSelected ? (
                    <MdFileOpen size={50} className="text-mainColor" />
                  ) : (
                    <p>{file?.name}</p>
                  )}
                </label>
                <p>
                  Drag and drop your file here or{" "}
                  <span
                    onClick={(e) => {
                      document.getElementById("file")?.click();
                    }}
                    className="text-mainColor cursor-pointer"
                  >
                    browse
                  </span>
                </p>
                <input
                  onChange={(e) => handleFileChange(e)}
                  id="file"
                  name="file"
                  type="file"
                  accept=".csv"
                  className="p-2 hidden border border-gray-300 rounded-md"
                />
              </div>
              {isFileSelected && (
                <button
                  disabled={uploading}
                  onClick={handleImport}
                  className="bg-mainColor text-white p-2 rounded-lg"
                >
                  Start Import
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProspectMain;

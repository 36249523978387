import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ownerSelector } from "../../store/store";

import {
  getAuth,
  updateEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { updateOwner } from "../../store/features/ownerActions";
import { useNavigate } from "react-router-dom";
import { setOwner } from "../../store/slices/ownerSlice";

type Props = {};

const firebaseConfig = {
  apiKey: "AIzaSyA-W3Xps8pdw6_ZHj_RQn-iPZ33spoJh2o",
  authDomain: "leadseeder-9abfc.firebaseapp.com",
  projectId: "leadseeder-9abfc",
  storageBucket: "leadseeder-9abfc.appspot.com",
  messagingSenderId: "572276511243",
  appId: "1:572276511243:web:3397589ac6fb68ccfded0e",
  measurementId: "G-880QGQNNY2",
};
const ProfileSettings = (props: Props) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const { owner } = useSelector(ownerSelector);
  const [ownerName, setOwnerName] = useState(owner?.name);
  const [ownerEmail, setOwnerEmail] = useState(
    owner?.email || ""
  );
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const navigate = useNavigate();

  // if (!owner) {
  //   navigate("/login");
  // }

  // const reauthenticateWithGoogle = async () => {
  //   const auth = getAuth();
  //   const provider = new GoogleAuthProvider();

  //   try {
  //     const result = await signInWithPopup(auth, provider);
  //     // The signed-in user info.
  //     const user = result.user;
  //     if (user.email !== owner.email) {
  //       toast.error(
  //         "You must sign in with the same account you used to sign up"
  //       );
  //       return null; // Re-authentication failed
  //     }
  //     return user; // Successfully re-authenticated
  //   } catch (error: any) {
  //     toast.error(` ${error.message}`);
  //     return null; // Re-authentication failed
  //   }
  // };
  const handleUpdateProfile = async () => {
    try {
      if (ownerName === "" || ownerEmail === "") {
        toast.error("Name and email cannot be empty");
      }
      // const user = await reauthenticateWithGoogle();
      // if (!user) return; // Stop if re-authentication fails

      try {
        // await updateEmail(user, ownerEmail);
        // await sendEmailVerification(user);
        const res = await dispatch(
          updateOwner({
            ownerId: owner?._id,
            name: ownerName,
            email: ownerEmail,
          })
        );

        if (res.meta.requestStatus === "fulfilled") {
          toast.success("Profile updated successfully.");
          dispatch(setOwner({ ...owner, name: ownerName, email: ownerEmail }));
        } else {
          toast.error("Error updating profile");
        }
      } catch (error: any) {
        toast.error(` ${error.message}`);
        setOwnerName(owner?.name);
        setOwnerEmail(owner?.email || "");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="p-2 w-full flex h-full flex-col gap-2 ">
      <h1 className="text-lg font-semibold">My Profile</h1>
      <div className="bg-white text-xs shadow-md relative w-[60%] h-[50%] flex flex-col gap-4 border-mainColor/20 border-2 p-4 rounded-lg">
        <h1 className="font-semibold">About you</h1>
        <div className="flex items-center gap-4">
          <img src={owner?.picture} alt="" className="w-20 h-20 rounded-full" />
          <div className="">
            <label htmlFor="name" className="text-sm">
              Name
            </label>
            <input
              onChange={(e) => {
                setOwnerName(e.target.value);
              }}
              type="text"
              placeholder="Name"
              id="name"
              className="border-2 outline-none border-mainColor/30 rounded-lg p-2 w-full"
              value={ownerName}
            />
          </div>
          <div>
            <label htmlFor="email" className="text-sm">
              Email
            </label>
            <input
              onChange={(e) => {
                setOwnerEmail(e.target.value);
              }}
              type="email"
              placeholder="Email"
              id="email"
              className="border-2 outline-none border-mainColor/30 rounded-lg p-2 w-full"
              value={ownerEmail}
            />
          </div>
        </div>
        <div className="absolute bottom-2 right-2">
          <button
            onClick={handleUpdateProfile}
            className="bg-mainColor text-white p-2 rounded-lg"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../ExpiredPlanModal.css';


interface ExpiredPlanModalProps {
  show: boolean;
  onClose: () => void;
}

const ExpiredPlanModal: React.FC<ExpiredPlanModalProps> = ({ show, onClose }) => {
  const navigate = useNavigate();

  if (!show) return null;

  const handleButtonClick = () => {
    navigate('/billings');
    onClose();
  };

  

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2 className="modal-title">Your Plan Has Expired</h2>
        <p className="modal-message">
          It looks like your current plan has expired. Please visit the billing page to renew your plan and regain access to premium features.
        </p>
        <p className="modal-note">
          Contact support via chat to get an exclusive offer!
        </p>
        <button onClick={handleButtonClick} className="modal-link">
          Go to Billing
        </button>
        <button className="modal-close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ExpiredPlanModal;
import { Checkbox, Popover, Table, TableColumnType, TableProps } from "antd";
import { useEffect, useRef, useState } from "react";
import { FaChartSimple, FaPencil, FaPeopleGroup } from "react-icons/fa6";
import { IoIosLeaf } from "react-icons/io";

import { MdCancel, MdCheck, MdHourglassBottom, MdPause } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  campaignSelector,
  dashboardSelector,
  ownerSelector,
  RootState,
} from "../../store/store";
import axios from "../../utils/axios";
import { RxAvatar } from "react-icons/rx";
import ProfileModal from "../Modals/ProfileModal";
import { setCurrentProspect } from "../../store/slices/prospectsSlice";
import { useNavigate } from "react-router-dom";
import { setListId } from "../../store/slices/ownerSlice";
import { toast } from "react-toastify";
import { BsClock } from "react-icons/bs";
import { Action, Campaign, Prospect, Tag } from "../../types";
import { Action as ReduxAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { fetchCampaignData } from "../../store/features/campaignsFeature";
import { CiSearch } from "react-icons/ci";
import Overview from "./Overview";

type Props = {
  onClose: () => void;
};

const CampaignModal = (props: Props) => {
  const { campaignId, owner } = useSelector(ownerSelector);
  const { singleCampaign } = useSelector(campaignSelector);
  const [campaignActions, setCampaignActions] = useState<Action[]>(
    [] as Action[]
  );
  const { campaignsData } = useSelector(dashboardSelector);
  const [numberOfActionsSent, setNumberOfActionsSent] = useState(0);
  const [numberOfPendingActions, setNumberOfPendingActions] = useState(0);
  const [prospectsWiseActions, setProspectsWiseActions] = useState(
    singleCampaign.prospectsWiseCampaignActions
  );
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("prospects");
  const [selectedInsight, setSelectedInsight] = useState("overview");
  const dispatch: ThunkDispatch<RootState, undefined, ReduxAction> =
    useDispatch();
  const navigate = useNavigate();

  const campaignModalRef = useRef<HTMLDivElement>(null);
  const filterDropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (showProfileModal) return;
    const handleClickOutside = (e: MouseEvent) => {
      if (filterDropdownRef.current?.contains(e.target as Node)) return;
      if (
        campaignModalRef.current &&
        !campaignModalRef.current.contains(e.target as Node) &&
        !filterDropdownRef.current?.contains(e.target as Node)
      ) {
        props.onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props, showProfileModal]);

  useEffect(() => {
    const handleFilter = () => {
      if (filterOptions.length === 0) {
        setProspectsWiseActions(singleCampaign.prospectsWiseCampaignActions);
      }
      if (filterOptions.includes("INVITATION_SENT")) {
        const filteredProspects = prospectsWiseActions.filter(
          (prospect: any) => prospect.connected === "PENDING"
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("INVITATION_PENDING")) {
        const filteredProspects = prospectsWiseActions.filter(
          (prospect: any) => prospect.connected === "NOT_STARTED"
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("PROFILE_VISITED")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) => action.type === "visit" && action.done === "DONE"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("VISIT_PENDING")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) =>
              action.type === "visit" && action.done === "NOT_STARTED"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("MESSAGE_SENT")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) => action.type === "message" && action.done === "DONE"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("MESSAGE_PENDING")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) =>
              action.type === "message" && action.done === "NOT_STARTED"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("MESSAGE_SCHEDULED")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) =>
              action.type === "messageWithDelay" && action.done === "SCHEDULED"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("DELAYED_MESSAGE_SENT")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) =>
              action.type === "messageWithDelay" && action.done === "DONE"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("DELAYED_MESSAGE_PENDING")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) =>
              action.type === "messageWithDelay" &&
              action.done === "NOT_STARTED"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("VISIT_SCHEDULED")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) =>
              action.type === "visitWithDelay" && action.done === "SCHEDULED"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("DELAYED_VISIT_DONE")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) =>
              action.type === "visitWithDelay" && action.done === "DONE"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
      if (filterOptions.includes("DELAYED_VISIT_PENDING")) {
        const filteredProspects = prospectsWiseActions.filter((prospect: any) =>
          prospect.campaignActions.find(
            (action: any) =>
              action.type === "visitWithDelay" && action.done === "NOT_STARTED"
          )
        );
        setProspectsWiseActions(filteredProspects);
      }
    };

    handleFilter();
  }, [filterOptions, singleCampaign, campaignsData]);
  useEffect(() => {
    (async () => {
      try {
        // const loader = toast.loading("Fetching campaign data...");
        const response = await dispatch(fetchCampaignData(campaignId));
        if (response.meta.requestStatus === "fulfilled") {
          // toast.dismiss(loader);
          // toast.success("Campaign fetched successfully");
          // console.log(response.payload.campaign);

          setCampaignActions(singleCampaign.actions);

          const actionsSent =
            singleCampaign.prospectsWiseCampaignActions.filter(
              (prospect: any) =>
                prospect.campaignActions.find(
                  (action: any) => action.done === "DONE"
                )
            );
          const totalActions =
            singleCampaign.actions.length * singleCampaign.prospects.length;
          setNumberOfActionsSent(actionsSent.length);
          setNumberOfPendingActions(totalActions - actionsSent.length);
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [numberOfActionsSent, numberOfPendingActions]);

  let actionColumns: TableColumnType<any>[] = [];

  singleCampaign.actions.forEach((action, index) => {
    if (action.type === "messageWithDelay") {
      actionColumns.push({
        title: "DELAYED MESSAGE",
        dataIndex: `${index}`,
        key: `${index}`,
        filterDropdown: (
          <div ref={filterDropdownRef} className="p-4 flex flex-col">
            <>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([...filterOptions, "MESSAGE_SCHEDULED"]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "MESSAGE_SCHEDULED"
                      )
                    );
                  }
                }}
              >
                Message Scheduled
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "DELAYED_MESSAGE_SENT",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "DELAYED_MESSAGE_SENT"
                      )
                    );
                  }
                }}
              >
                Message Sent
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "DELAYED_MESSAGE_PENDING",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "DELAYED_MESSAGE_PENDING"
                      )
                    );
                  }
                }}
              >
                Message Pending
              </Checkbox>
            </>
          </div>
        ),
      });
    } else if (action.type === "visitWithDelay") {
      actionColumns.push({
        title: "Delayed Visit",
        dataIndex: `${index}`,
        key: `${index}`,
        filterDropdown: (
          <div ref={filterDropdownRef} className="p-4 flex flex-col">
            <>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([...filterOptions, "VISIT_SCHEDULED"]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter((item) => item !== "VISIT_SCHEDULED")
                    );
                  }
                }}
              >
                Visit Scheduled
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([...filterOptions, "DELAYED_VISIT_DONE"]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "DELAYED_VISIT_DONE"
                      )
                    );
                  }
                }}
              >
                Visit Done
              </Checkbox>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterOptions([
                      ...filterOptions,
                      "DELAYED_VISIT_PENDING",
                    ]);
                  } else {
                    setFilterOptions(
                      filterOptions.filter(
                        (item) => item !== "DELAYED_VISIT_PENDING"
                      )
                    );
                  }
                }}
              >
                Visit Pending
              </Checkbox>
            </>
          </div>
        ),
      });
    } else {
      actionColumns.push({
        title: action.type.toUpperCase(),
        dataIndex: `${index}`,
        key: `${index}`,
        filterDropdown: (
          <div ref={filterDropdownRef} className="p-4 flex flex-col">
            {action.type === "invitation" && (
              <>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "INVITATION_SENT"]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "INVITATION_SENT")
                      );
                    }
                  }}
                >
                  Invitation Sent
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([
                        ...filterOptions,
                        "INVITATION_PENDING",
                      ]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "INVITATION_PENDING")
                      );
                    }
                  }}
                >
                  Invitation Pending
                </Checkbox>
              </>
            )}
            {action.type === "visit" && (
              <>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "PROFILE_VISITED"]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "PROFILE_VISITED")
                      );
                    }
                  }}
                >
                  Visit Done
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "VISIT_PENDING"]);
                    } else {
                      setFilterOptions((prev) =>
                        prev.filter((item) => item !== "VISIT_PENDING")
                      );
                    }
                  }}
                >
                  Visit Pending
                </Checkbox>
              </>
            )}
            {action.type === "message" && (
              <>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "MESSAGE_SENT"]);
                    } else {
                      setFilterOptions(
                        filterOptions.filter((item) => item !== "MESSAGE_SENT")
                      );
                    }
                  }}
                >
                  Message Sent
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilterOptions([...filterOptions, "MESSAGE_PENDING"]);
                    } else {
                      setFilterOptions(
                        filterOptions.filter(
                          (item) => item !== "MESSAGE_PENDING"
                        )
                      );
                    }
                  }}
                >
                  Message Pending
                </Checkbox>
              </>
            )}
          </div>
        ),
      });
    }
  });

  const campaignTableColumns: TableColumnType<any>[] = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    ...actionColumns,
    {
      title: "TAGS",
      dataIndex: "tags",
      key: "tags",
    },
  ];

  const campaignTableData: any = [];
  console.log("here");

  prospectsWiseActions.forEach((prospect, index) => {
    let temp: any = {
      key: prospect._id,
      name: (
        <div
          onClick={() => {
            dispatch(setCurrentProspect(prospect));
            setShowProfileModal(true);
          }}
          className="flex items-center gap-2 font-primary cursor-pointer"
        >
          {prospect.image_url ? (
            <img
              src={prospect.image_url}
              alt=""
              className="w-8 h-8 rounded-full"
            />
          ) : (
            <RxAvatar
              className="w-10 h-10 text-mainColor cursor-pointer"
              // size={20}
            />
          )}
          <p className="text-xs">
            {prospect.full_name?.length > 20 ? (
              <Popover content={prospect.full_name}>
                {prospect.full_name.slice(0, 20) + "..."}
              </Popover>
            ) : (
              prospect.full_name
            )}
          </p>
        </div>
      ),
      tags: (
        <div className="flex gap-2">
          {prospect.tags.length > 0 ? (
            prospect.tags.map((tag: Tag, index: number) => (
              <div key={index} className="flex items-center gap-2">
                <p
                  style={{ backgroundColor: tag.color }}
                  className={`text-xs py-0.5 px-2 cursor-pointer rounded-xl text-white whitespace-nowrap`}
                >
                  {tag.name}
                </p>
              </div>
            ))
          ) : (
            <p className="text-xs p-1 whitespace-nowrap text-center bg-gray-500/50 text-white rounded-md">
              No tags
            </p>
          )}
        </div>
      ),
    };

    let hasReplied = false;
    prospect.campaignActions.forEach((action: any, index: number) => {
      if (action.done == "HASREPLIED") {
        hasReplied = true;
      }
      if (hasReplied) {
        temp[`${index}`] = (
          <div className="flex items-center gap-2 font-primary">
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Has Replied</p>
            </p>
          </div>
        );
      } else if(action.done == "Error") {
        temp[`${index}`] = (
          <div className="flex items-center gap-2 font-primary">
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-red-500/80 flex gap-2 text-white rounded-md">
              <MdCancel size={15} />
              <p>Error</p>
            </p>
          </div>
        );
      } else if (action.type == "invitation") {
        temp[`${index}`] = (
          <div className="flex items-center gap-2 font-primary">
            {prospect.connected === "ACCEPTED" ? (
              <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
                <MdCheck size={15} />
                <p>Invitation Accepted</p>
              </p>
            ) : prospect.connected === "PENDING" ? (
              <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-blue-500/80 flex gap-2 text-white rounded-md">
                <BsClock size={15} />

                <p>Invitation Sent</p>
              </p>
            ) : (
              <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
                <MdHourglassBottom size={15} />

                <p>Invitation Pending</p>
              </p>
            )}
          </div>
        );
      } else if (action.type == "visit") {
        if (action.done == "DONE") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Profile Visited</p>
            </p>
          );
        } else {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Visit Pending</p>
            </p>
          );
        }
      } else if (action.type == "message") {
        if (action.done == "DONE") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Message Sent</p>
            </p>
          );
        } else {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Message Pending</p>
            </p>
          );
        }
      } else if (action.type == "messageWithDelay") {
        if (action.done == "SCHEDULED") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-blue-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Message Scheduled</p>
            </p>
          );
        } else if (action.done == "DONE") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Message Sent</p>
            </p>
          );
        } else {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Message Pending</p>
            </p>
          );
        }
      } else if (action.type == "visitWithDelay") {
        if (action.done == "SCHEDULED") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-blue-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Visit Scheduled</p>
            </p>
          );
        } else if (action.done == "DONE") {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-green-500/80 flex gap-2 text-white rounded-md">
              <MdCheck size={15} />
              <p>Visit Done</p>
            </p>
          );
        } else {
          temp[`${index}`] = (
            <p className="text-xs w-fit p-1 whitespace-nowrap text-center bg-orange-500/80 flex gap-2 text-white rounded-md">
              <MdHourglassBottom size={15} />
              <p>Visit Pending</p>
            </p>
          );
        }
      }
    });

    campaignTableData.push(temp);
  });

  const handleSearchProspect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    if (searchValue === "") {
      setProspectsWiseActions(singleCampaign.prospectsWiseCampaignActions);
      return;
    }
    const filteredProspects = prospectsWiseActions.filter((prospect) =>
      prospect.full_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setProspectsWiseActions(filteredProspects);
  };
  return (
    <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-sm bg-black/20">
      <div>
        <div
          ref={campaignModalRef}
          className="bg-white w-[80%] min-h-[90%] text-xs absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 rounded-lg p-4"
        >
          <span
            className="text-mainColor absolute right-4 cursor-pointer"
            onClick={props.onClose}
          >
            <MdCancel size={25} />
          </span>

          <div className="bg-mainColor/30 flex w-full items-center justify-between mt-10 p-4 rounded-md">
            <div className="flex items-center gap-2">
              <h1 className="font-semibold text-lg">
                {singleCampaign?.campaignName ?? ""}
              </h1>
              <div className="border-2 border-mainColor flex gap-2 p-1 rounded-md items-center bg-white text-xs">
                <CiSearch size={20} />
                <input
                  onChange={handleSearchProspect}
                  type="text"
                  placeholder="Search prospects"
                  className="outline-none w-full"
                />
              </div>
            </div>
            <div className="flex justify-center items-center gap-2">
              <p>Imported from</p>
              <p className="bg-mainColor text-white p-1 rounded-lg text-sm">
                {singleCampaign?.listId?.listName ?? ""}
              </p>
            </div>

            {/* <button className="bg-mainColor flex items-center shadow-md gap-2 font-semibold text-white p-2 px-4 rounded-md">
              <FaPencil />
              <p>Edit</p>
            </button> */}
          </div>
          {/* <div className="flex w-full justify-around items-center mt-2 p-2 bg-mainColor/40 text-black rounded-lg">
            <div
              onClick={() => setSelectedTab("prospects")}
              className={`${
                selectedTab === "prospects" &&
                "bg-mainColor hover:bg-mainColor/70 text-white "
              }  w-[50%] flex items-center justify-center p-2 rounded-lg font-semibold cursor-pointer gap-2 transition duration-300 text-sm`}
            >
              <FaPeopleGroup size={20} />
              <p>Prospects</p>
            </div>
            <div
              onClick={() => setSelectedTab("analytics")}
              className={`${
                selectedTab === "analytics" &&
                "bg-mainColor hover:bg-mainColor/70 text-white"
              }  w-[50%] flex items-center justify-center p-2 rounded-lg font-semibold cursor-pointer gap-2 transition duration-300 text-sm`}
            >
              <FaChartSimple size={20} />
              <p>Analytics</p>
            </div>
          </div> */}
          {selectedTab === "prospects" ? (
            <div className="flex w-full max-h-[80%] gap-4 bg-mainColor/20 mt-2 rounded-lg">
              <div className="w-[25%]  overflow-hidden flex flex-col items-center border-r-2 border-white px-2">
                <div className="w-full font-semibold flex justify-around bg-white rounded-lg m-2  p-2">
                  <span
                    onClick={() => setSelectedInsight("overview")}
                    className={`${
                      selectedInsight === "overview" &&
                      "bg-mainColor hover:bg-mainColor/70 text-white"
                    }  w-[50%] flex items-center justify-center p-2 rounded-lg font-semibold cursor-pointer transition duration-300`}
                  >
                    Overview
                  </span>
                  <span
                    onClick={() => setSelectedInsight("details")}
                    className={`${
                      selectedInsight === "details" &&
                      "bg-mainColor hover:bg-mainColor/70 text-white"
                    }  w-[50%] flex items-center p-2 justify-center rounded-lg font-semibold cursor-pointer transition duration-300`}
                  >
                    Details
                  </span>
                </div>
                {!campaignsData ? (
                  <div className="h-full flex flex-col justify-center items-center bg-white my-2 w-full rounded-xl">
                    <IoIosLeaf size={100} className="text-mainColor" />
                    <p className="text-lg font-semibold">
                      Oh snap! Nothing to see.
                    </p>
                  </div>
                ) : selectedInsight === "overview" ? (
                  <Overview />
                ) : (
                  <div className="h-full gap-2 flex flex-col justify-start items-center bg-white my-2 w-full rounded-xl p-2">
                    <h1 className=" font-semibold">Action Details</h1>
                    <div className="w-full flex justify-between items-center p-2 bg-mainColor/30 rounded-lg">
                      <p className="font-semibold">Action</p>
                      <p className="font-semibold">Status</p>
                    </div>

                    {singleCampaign?.actions?.map(
                      (action: any, index: number) => (
                        <div
                          key={index}
                          className="w-full flex justify-between items-center p-2 bg-white rounded-lg"
                        >
                          {action.type === "messageWithDelay" ? (
                            <div className="w-full flex flex-col gap-4">
                              <div className="flex items-center  gap-4 w-full">
                                <p>Delay</p>
                                <p className="text-xs text-gray-400">
                                  {action.delay} days
                                </p>
                              </div>
                              <p>Message</p>
                            </div>
                          ) : action.type === "visitWithDelay" ? (
                            <div className="w-full flex flex-col gap-4">
                              <div className="flex items-center  gap-4 w-full">
                                <p>Delay</p>
                                <p className="text-xs text-gray-400">
                                  {action.delay} days
                                </p>
                              </div>
                              <p>Visit</p>
                            </div>
                          ) : (
                            <p>{action.type.toUpperCase()}</p>
                          )}

                          {singleCampaign.campaignStatus === "PAUSED" ||
                          singleCampaign.campaignStatus === "ARCHIVED" ||
                          singleCampaign.campaignStatus === "COMPLETED" ? (
                            action.done === "DONE" ? (
                              <Popover content={"Completed"}>
                                <MdCheck size={20} className="text-green-500" />
                              </Popover>
                            ) : singleCampaign.campaignStatus === "PAUSED" ? (
                              <Popover content={"Paused"}>
                                <MdPause
                                  size={20}
                                  className="text-yellow-500"
                                />
                              </Popover>
                            ) : (
                              singleCampaign.campaignStatus === "ARCHIVED" && (
                                <Popover content={"Cancelled"}>
                                  <MdCancel
                                    size={20}
                                    className="text-red-500"
                                  />
                                </Popover>
                              )
                            )
                          ) : (
                            <p>
                              {action.done === "DONE" ? (
                                <Popover content={"Completed"}>
                                  <MdCheck
                                    size={20}
                                    className="text-green-500"
                                  />
                                </Popover>
                              ) : action.done === "NOT_STARTED" ? (
                                <Popover content="In Progress">
                                  <MdHourglassBottom
                                    size={20}
                                    className="text-gray-500"
                                  />
                                </Popover>
                              ) : (
                                <MdCancel size={20} className="text-red-500" />
                              )}
                            </p>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <div className="w-[75%] bg-white flex justify-center items-center">
                <div className="w-full relative h-[65vh] overflow-x-scroll slimScrollbar">
                  <Table
                    columns={campaignTableColumns}
                    dataSource={campaignTableData}
                    pagination={false}
                    className="slimScrollbar bg-white"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="w-full h-[50vh] justify-center flex  my-auto items-center flex-col">
                <FaChartSimple size={50} className="text-mainColor" />
                <p className="font-semibold">
                  Analytics Coming Soon! Stay tuned.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {showProfileModal && (
        <ProfileModal onClose={() => setShowProfileModal(false)} />
      )}
    </div>
  );
};

export default CampaignModal;
